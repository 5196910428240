<template>
  <fieldset :disabled="disabled">
    <div class="animated fadeIn container novoLayout">
      <b-row>
        <b-col md="12" class="card" style="padding-top: 20px">
          <b-card no-body>
            <b-card-header> Cadastro de Usuários </b-card-header>
            <b-card-body>
              <b-row>
                <b-col md="2" offset-md="10" class="text-right mb-3">
                  <b-form-checkbox v-model="bo_ativo" switch
                    >Ativo</b-form-checkbox
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col md="7">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Usuário</span>
                    </div>
                    <b-form-input
                      id="nome"
                      type="text"
                      placeholder="Nome de usuário"
                      v-model="nome"
                    />
                  </div>
                </b-col>
                <b-col md="5">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Grupo</span>
                    </div>
                    <b-form-select
                      v-model="grupo"
                      :options="grupos"
                    ></b-form-select>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="7">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">@</span>
                    </div>
                    <b-form-input
                      id="email"
                      type="text"
                      placeholder="E-mail do usuário"
                      v-model="email"
                      :readonly="usuario_id != ''"
                    />
                  </div>
                </b-col>
                <b-col md="5">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">WhatsApp</span>
                    </div>
                    <b-form-input
                      id="whatsapp"
                      type="text"
                      placeholder="WhatsApp"
                      v-model="whatsapp"
                      v-mask="'(##) #####-####'"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-card no-body v-if="revendasRede.length > 0">
            <b-card-header> Outras configurações </b-card-header>
            <b-card-body>
              <b-row>
                <b-col md="12">
                  <b-card no-body>
                    <b-card-header class="cardHeaderPadrao">
                      <b>Outras empresas do grupo </b>
                      <i
                        class="fa fa-question-circle"
                        title="Defina as empresas do grupo ao qual o usuário terá acesso."
                      ></i>
                    </b-card-header>
                    <b-card-body>
                      <div class="row" v-for="revenda in revendasRede">
                        <div class="col-1 text-center">
                          <b-form-checkbox
                            switch
                            v-model="revenda.ativo"
                          ></b-form-checkbox>
                        </div>
                        <div class="col-7">{{ revenda.NM_PESSOA }}</div>
                        <div class="col-4">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text">Grupo</span>
                            </div>
                            <b-form-select
                              v-model="revenda.grupo"
                              :options="revenda.grupos"
                              :disabled="!revenda.ativo"
                            ></b-form-select>
                          </div>
                        </div>
                      </div>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <b-row>
            <b-col md="12" class="text-right mb-3">
              <b-button
                v-on:click="confirmDelete"
                variant="outline-danger"
                class="m-1"
                :disabled="usuarioConfirmado || !usuario_id"
              >
                Excluir
              </b-button>
              <b-button
                v-on:click="cancelarConvite"
                variant="outline-danger"
                class="m-1"
                :disabled="!tokenValido || usuarioConfirmado || !usuario_id"
              >
                Cancelar convite
              </b-button>
              <b-button
                v-on:click="reenviarConvite"
                variant="outline-dark"
                class="m-1"
                :disabled="tokenValido || usuarioConfirmado || !usuario_id"
              >
                Reenviar convite
              </b-button>
              <b-button v-on:click="cancel" variant="outline-dark" class="m-1">
                Cancelar
              </b-button>
              <b-button
                v-on:click="saveUsuario"
                variant="outline-info"
                class="m-1"
              >
                Salvar
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </fieldset>
</template>

<script>
import Usuario from "../../../services/usuario";
import GrupoPermissao from "../../../services/grupoPermissao";
import Revenda from "../../../services/revenda";
import "bootstrap/dist/js/bootstrap.min.js";
import Vue from "vue";
import VueTheMask from "vue-the-mask";
import moment from "moment";
Vue.use(VueTheMask);

export default {
  name: "UsuarioForm",
  data: () => ({
    usuario_id: 0,
    nome: "",
    grupo: "",
    email: "",
    whatsapp: "",
    bo_ativo: true,
    disabled: false,
    grupos: [],
    revendasRede: [],
    tokenValido: false,
    usuarioConfirmado: false,
  }),
  mounted() {
    this.validarPermissao();
    this.buscarGrupos();
    if (this.$route.params.id) {
      this.loadUsuario(this.$route.params.id);
    } else {
      this.buscarRevendas();
    }
  },
  methods: {
    validarPermissao() {
      GrupoPermissao.getPermissao("/configuracao/usuario").then((response) => {
        if (!response.data.data) this.$router.push("/");
        else this.disabled = response.data.data.edita != "1";
      });
    },
    loadUsuario(id) {
      Usuario.getById(id)
        .then((response) => {
          this.usuario_id = response.data.data.id;
          this.nome = response.data.data.first_name;
          this.grupo = response.data.data.grupo;
          this.email = response.data.data.email;
          this.whatsapp = response.data.data.whatsapp;
          this.tokenValido =
            response.data.data.token != "" &&
            moment(response.data.data.token_validade).format(
              "YYYY-MM-DD HH:mm:ss"
            ) >= moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          this.usuarioConfirmado = response.data.data.password != "";
          if (response.data.data.bo_ativo == "S") this.bo_ativo = true;
          else this.bo_ativo = false;
          this.buscarRevendas();
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    buscarGrupos() {
      GrupoPermissao.getGruposPermissaoDropbox()
        .then((response) => {
          this.grupos = response.data.data;
        })
        .catch((e) => {
          if (e.response.status === 404) return;
          this.$helper.showErrorResponse(e);
        });
    },
    saveUsuario() {
      if (this.whatsapp.length < 14) {
        this.$helper.showMsg("Número whatsapp incompleto", "warning");
        return;
      }

      let data = {
        id: this.usuario_id,
        revenda_codigo: this.$store.state.login.resale.revenda_codigo,
        bo_ativo: this.bo_ativo,
        nome: this.nome,
        grupo: this.grupo,
        email: this.email,
        whatsapp: this.whatsapp,
        revendasRede: this.revendasRede,
      };
      Usuario.saveUsuario(data)
        .then((response) => {
          if (response.data.status == 200)
            this.$router.push("/configuracao/usuario");
          else this.$helper.showMsg(response.data.msg, response.data.type);
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    cancelarConvite() {
      Usuario.cancelarConvite(this.usuario_id)
        .then((response) => {
          if (response.data.status == 200) {
            this.tokenValido = false;
            this.$helper.showMsg("Convite cancelado com sucesso", "success");
          } else this.$helper.showMsg(response.data.msg, response.data.type);
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    reenviarConvite() {
      Usuario.reenviarConvite(this.usuario_id)
        .then((response) => {
          if (response.data.status == 200) {
            this.tokenValido = true;
            this.$helper.showMsg("Convite reenviado com sucesso", "success");
          } else this.$helper.showMsg(response.data.msg, response.data.type);
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    cancel() {
      this.$router.push("/configuracao/usuario");
    },
    buscarRevendas() {
      Revenda.getRevendasDaRede(this.usuario_id)
        .then((response) => {
          this.revendasRede = response.data;
        })
        .catch((e) => {
          if (e.response.status === 404) return;
          this.$helper.showErrorResponse(e);
        });
    },
    confirmDelete() {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja excluir o Usuário?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "md",
          okVariant: "outline-success",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            Usuario.deleteUsuario(this.usuario_id)
              .then((response) => {
                this.$helper.showMsg(response.data.msg, response.data.type);
                this.$router.push("/configuracao/usuario");
              })
              .catch((e) => {
                this.$helper.showErrorResponse(e);
              });
          }
        })
        .catch((err) => {
          this.$helper.showErrorResponse(err);
        });
    },
  },
};
</script>

<style scoped>
.headerPermissoes {
  height: 50px;
  padding: 13px !important;
  font-weight: bold;
  background-color: #f8f8fa !important;
  color: #000 !important;
}

.accordion > .card {
  overflow: initial;
}

.accordion > .card:first-of-type {
  border-bottom: inset;
}

.cabecalho {
  font-weight: bold;
  color: #423292;
  border-bottom: 1px solid #c8ced3;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.colBordaDireita {
  border-right: 1px solid #c8ced3;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  display: block;
  margin-right: 20px;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23229'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.usuarios .row {
  border-bottom: 1px solid #efefef;
}

.usuarios .col {
  border-right: 1px solid #efefef;
}

.usuarios .custom-control {
  display: inline !important;
}

.novoLayout .card-header {
  background-color: #cfc7f3;
  color: #3634a3;
}

.cardHeaderPadrao {
  background-color: #f0f3f5 !important;
  color: #000 !important;
}

.accordion > .card[data-v-468087f6]:first-of-type {
  border-bottom: 0px;
}
</style>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #4038b7 !important;
  background-color: #4038b7 !important;
}
</style>