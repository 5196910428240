import { http } from './config'

export default {
    trocarSenha: (userName, oldPassword, password) => {
        return http.put('usuario/trocar-senha',
            {
                "username": userName,
                "oldpassword": oldPassword,
                "newpassword": password
            }
        )
    },
    getUsuarios: (data, params = null) => {
        params = params ? "?" + params : "/"
        return http.get('usuario/list' + params, data)
    },
    getById: (id) => {
        return http.get('usuario/get/' + id)
    },
    saveUsuario: (data) => {
        return http.post('usuario/save', data)
    },
    deleteUsuario: (id) => {
        return http.delete('usuario/' + id)
    },
    getDadosCadastro: (code) => {
        return http.get('cadastro/get-cadastro?code=' + code)
    },
    cancelarConvite: (id) => {
        return http.put('usuario/cancelar-convite/' + id)
    },
    reenviarConvite: (id) => {
        return http.put('usuario/reenviar-convite/' + id)
    },
    deleteUsuario: (id) => {
        return http.delete('usuario/' + id)
    },
    confirmarUsuario: (data) => {
        return http.post('cadastro/confirmar', data)
    },
}